import type {Buildweb,ChoosePlan,faqType } from "@/types/demos/itpage";

/*import tabler icons*/
import { CheckIcon, XIcon, } from 'vue-tabler-icons';
/*-- Pricing Page --*/
const ChoosePlanData: ChoosePlan[] = [
    {
        caption: 'Basic',
        price: 9,
        yearlyprice: 9 * 12,
        buttontext: 'Try free for 14 days',
        list: [
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Monthly service fee',
                status:false,
            },
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Direct debits',
                status:false,
            },
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Online payments',
                status:false,
            },
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Deposits to Savers',
                status:false,
            },
             {
                icon: XIcon,
                iconcolor: 'muted',
                bgcolor:'lightinfo',
                listtitle: 'International transaction fees',
                status:false,
            },
        ]
    },
    {
        caption: 'Professional',
        price: 18,
        yearlyprice: 18 * 12,
        buttontext: 'Try free for 14 days',
        list: [
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Monthly service fee',
                status:false,
            },
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Direct debits',
                status:false,
            },
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Online payments',
                status:false,
            },
            {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'Deposits to Savers',
                status:false,
            },
             {
                icon: CheckIcon,
                iconcolor: 'primary',
                bgcolor:'lightinfo',
                listtitle: 'International transaction fees',
                status:false,
            },
        ]
    },

];

const faqData: faqType[] = [
    {
        question: 'When will you launch?',
        answer: 'We will start accepting pitches on 1st of October 2024. We expect to kickoff the first batch of startups shortly after.'
    },
    {
        question: 'What type of startups are you looking for?',
        answer: 'Generally SaaS in the enterprise and consumer sector.'
    },
    {
        question: 'What software technology are you able to build?',
        answer: 'Almost anything. Complexity in itself is not a constraint, but Time Is and we prefer ideas with a quick path to market tests.'
    },    
    {
        question: 'Estne posticum eximii populi?',
        answer: 'Semper. Quaeso contactus latine et laeti erimus te nosse.'
    },
];


export {BuildwebData, ChoosePlanData, faqData};