<script setup lang="ts">
</script>
<template>
  <section>
    <div class="bg-lightinfo mt-8 mt-lg-8 py-md-16 py-10">
      <div class="py-md-16 py-5">
        <v-container>
          <v-row class="mx-n6">
            <v-col cols="12" md="6" class="px-6">
              <div
                class="work-revenue position-relative text-center text-sm-left z-index1"
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <h2 class="text-h2 font-weight-bold text-dark mb-6 pr-lg-16">
                    How can we help?
                  </h2>
                  <p class="text-muted mb-6 text-body-1">
                    We bring together founders, investors and workers to
                    optimise startups.
                  </p>
                </div>
                <v-card
                  elevation="10"
                  class="mt-md-15 mt-8 bg-image"
                  :height="$vuetify.display.smAndDown ? 'auto' : '600'"
                >
                  <div>
                    <v-img
                      src="/images/home/founders.png"
                      :height="$vuetify.display.smAndDown ? 'auto' : '600'"
                      class="img-fluid"
                      alt=""
                      cover
                    />
                  </div>
                  <v-card-item class="position-relative">
                    <div class="pa-sm-10 pa-5">
                      <h4
                        class="text-h4 font-weight-bold pr-md-16 mr-md-16 lh-26 text-dark mb-6 lh-28"
                      >
                        Founders
                      </h4>
                      <p class="text-muted mb-6 text-body-1">
                        Pitch us your idea. <br />
                        Get funded -> MVP -> Market fit -> Grow -> Graduate ...
                      </p>
                      <p class="text-center text-sm-start">
                      <NuxtLink
                        to="/founders"
                        class="font-weight-medium text-decoration-none d-flex align-center justify-center justify-sm-start text-primary card-link"
                      >
                       Learn More
                        <ChevronRightIcon
                          size="17"
                          class="ml-2 "
                          stroke-width="2.5"
                        />
                      </NuxtLink>
                      </p>
                    </div>
                  </v-card-item>
                </v-card>
                <div class="position-absolute shape z-index-n1"></div>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="px-6">
              <div
                class="work-revenue position-relative text-center text-sm-start z-index1"
              >
                <v-card
                  elevation="10"
                  class="z-index1 bg-image"
                  :height="$vuetify.display.smAndDown ? 'auto' : '600'"
                >
                  <div>
                    <v-img
                      src="/images/home/investors.png"
                      :height="$vuetify.display.smAndDown ? 'auto' : '600'"
                      class="img-fluid"
                      alt=""
                      cover
                    />
                  </div>
                  <v-card-item class="position-relative">
                    <div class="pa-sm-10 pa-5">
                      <h4
                        class="text-h4 font-weight-bold pr-md-16 mr-md-16 lh-26 text-dark mb-6 lh-28"
                      >
                        Investors
                      </h4>
                      <p class="text-muted mb-6 text-body-1">
                        Evaluate pitches, pick your winners, invest safely and
                        track your portfolio grow.
                      </p>
                      <NuxtLink
                        to="/investors"
                        class="font-weight-medium text-decoration-none d-flex align-center justify-center justify-sm-start text-primary card-link"
                      >
                        Learn More
                        <ChevronRightIcon
                          size="17"
                          class="ml-2"
                          stroke-width="2.5"
                        />
                      </NuxtLink>
                    </div>
                  </v-card-item>
                </v-card>
                <v-card
                  :height="$vuetify.display.smAndDown ? 'auto' : '600'"
                  elevation="10"
                  class="mt-md-12 mt-6 z-index1 bg-image"
                >
                  <div>
                    <v-img
                      src="/images/home/workers.png"
                      :height="$vuetify.display.smAndDown ? 'auto' : '600'"
                      class="img-fluid"
                      alt=""
                      cover
                    />
                  </div>
                  <v-card-item class="position-relative">
                    <div class="pa-sm-10 pa-5">
                      <h4
                        class="text-h4 font-weight-bold pr-md-16 mr-md-16 lh-26 text-dark mb-6 lh-28"
                      >
                        Workers
                      </h4>
                      <p class="text-muted mb-6 text-body-1">
                        Are you the best? Work hard and get a share of the
                        success you're creating.
                      </p>
                      <NuxtLink
                        to="/workers"
                        class="font-weight-medium text-decoration-none d-flex align-center justify-center justify-sm-start text-primary card-link"
                      >
                        Learn More
                        <ChevronRightIcon
                          size="17"
                          class="ml-2"
                          stroke-width="2.5"
                        />
                      </NuxtLink>
                    </div>
                  </v-card-item>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </section>
</template>