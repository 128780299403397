<script setup lang="ts">
import Header from '@/layouts/header/ColorHeader.vue';
import Banner from '@/components/home/Banner.vue';
// import BuildWebsite from '@/components/home/BuildWebsite.vue';
import TreeletForYou from '@/components/home/TreeletForYou.vue';
import Faq from '@/components/home/Faq.vue';
import Footer from '@/layouts/footer/ItFooter.vue';
</script>
<template>
    <!--Header-->
    <Header />
    <Banner />
    <!-- <BuildWebsite /> -->
    <TreeletForYou />
    <Faq />
    <Footer />
</template>
