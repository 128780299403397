<script setup lang="ts">
import { ref } from "vue";
const reviewstar1 = ref(5);
//const runtimeConfig = useRuntimeConfig();
import animationTop from "@/components/home/animationTop.vue";
import animationBottom from "@/components/home/animationBottom.vue";
//import AnimationBott from '@/components/home/AnimationBott.vue';

import VueWriter from "vue-writer";

//console.log(runtimeConfig.public.NUXT_MY_ENV_VARIABLE);
</script>
<template>
  <div class="it-wrapper position-relative">
    <div class="banner-shape position-relative">
      <div class="it-banner position-relative bg-darkprimarygray">
        <animationTop class="position-relative" />

        <v-container class="position-relative z-index1">
          <v-row class="align-center">
            <v-col cols="12" md="6">
              <div class="text-sm-start text-center">
                <h1 class="text-white text-h1 mb-8">
                  The best way to
                  <VueWriter
                    :array="['found', 'build', 'invest in']"
                    :repeat="Infinity"
                    data-aos-caret-animation="blink"
                    class="text-white text-h1"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                  />
                  your next startup ...
                </h1>

                <!-- 
                <h1
                  class="text-white text-h1 mb-8"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  Amazingly fexible, customizable and easy to use
                  The best way to [found/build/invest in] your next startup ...
                </h1> -->
                <NuxtLink to="/registration">
                  <v-btn
                    size="large"
                    class="bg-mint text-primary px-8"
                    variant="flat"
                  >
                    get started
                  </v-btn>
                </NuxtLink>
                <br /><br /><br /><br /><br /><br /><br />
                <!-- <div class="mt-sm-16 mt-8">
                  <v-rating
                    v-model="reviewstar1"
                    hover
                    density="compact"
                    half-increments
                    readonly
                    color="warning"
                    class="ml-n2"
                  ></v-rating>
                  <div class="mb-8 mt-5 pr-lg-16">
                    <p class="pr-lg-16 text-body-1 text-black">
                      Amazing team ... MVP built with time to spare for pivoting and growth. <br/>
                      Maria Saidy Garcia, <br/>Co-Founder & CEO Ashta.io
                    </p>
                  </div>
                  <img src="/images/home/ashta_logo.png" alt="logo" />
                </div> -->
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="justify-center position-relative">
                <v-col cols="12" md="9">
                  <!-- <div class="banner-circle-line d-none d-lg-block">
                    <img
                      src="/images/it/banner-circle-lines.png"
                      class="op-04"
                      alt=""
                    />
                  </div> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
  <div class="position-relative"><animationBottom /></div>
</template>
